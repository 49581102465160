.sgds-navbar-brand {
  a.sgds-navbar-item img {
    width: 200px;
    height: 80px;
    max-height: none;
  }
  .sgds-navbar-burger {
    height: auto;
  }
}
