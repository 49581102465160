:root{
  //color WIP
  
  //typography
  --font-size-h1-display: 5.25rem;
  --font-size-h1-mobile: 2.75rem;
  --font-size-h1-display-mobile: 3.5rem;

  //h1
  --font-size-h1: 3.375rem;
  --font-size-h1-lh: 3.75rem;

  --font-size-h2: 2.75rem;
  --font-size-h2-lh: 3.75rem;
  --font-size-h2-mobile: 2rem;

  --font-size-h3: 2rem;
  --font-size-h3-lh: 2.8125rem;
  --font-size-h3-mobile: 1.75rem;

  --font-size-h4: 1.625rem;
  --font-size-h4-lh: 2.25rem;
  --font-size-h4-mobile: 1.5rem;

  --font-size-h5: 1.375rem;
  --font-size-h5-lh: 1.875rem;

  --font-size-h6: 1.3rem;
  --font-size-h6-lh: 1.5rem;

  --font-size-p: 1.125rem;
  --font-size-p-lh: 2rem;
  --font-size-p-lh-content: 2.2rem;

  --font-size-small: 1rem;

  --card-variant-highlight-margin-top: 80px;

  //alignment
  --global-alignment: 1rem;
  --global-alignment-multiplier-xs: 0.25;
  --global-alignment-multiplier-sm: 0.5;
  --global-alignment-multiplier-lg: 2;
  --global-alignment-multiplier-xl: 4;

  //timepicker global height
  --timepicker-height: 70px;

  //radius for button/forms
  --radius: 0;
  --radius-sm: 2px;
  --radius-lg: 5px;
}

// Colors
$primary: hsl(260, 53%, 46%) !default;
$secondary: hsl(220, 51%, 49%) !default;

$black: hsl(0, 0%, 4%) !default;
$black-bis: hsl(0, 0%, 7%) !default;
$black-ter: hsl(0, 0%, 14%) !default;

$grey-darker: hsl(0, 0%, 20%) !default;
$grey-dark: hsl(0, 0%, 28%) !default;
$grey: hsl(0, 0%, 46%) !default;
$grey-light: hsl(0, 0%, 84%) !default;
$grey-lighter: hsl(0, 0%, 94%) !default;
$grey-lightest: hsl(0, 0%, 95%) !default;

$footer-grey: hsl(0, 0%, 72%);
$table-grey: hsl(200, 25%, 95%);

$white-trans: rgba(255, 255, 255, 0.7) !default;
$white-ter: hsl(0, 0%, 96%) !default;
$white-bis: hsl(0, 0%, 98%) !default;
$white: hsl(0, 0%, 100%) !default;

$orange: hsl(14,  100%, 53%) !default;
$yellow: hsl(37, 91%, 55%) !default;
$green: hsl(153, 100%, 26%) !default;
$cyan: hsl(188, 100%, 29%) !default;
$blue: hsl(212, 100%, 47%) !default;
$purple: hsl(271, 100%, 71%) !default;
$red: hsl(353, 98%, 41%) !default;

// Typography
$family-sans-serif: "Lato", BlinkMacSystemFont, -apple-system, "Segoe UI",
  "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$family-monospace: monospace !default;
$render-mode: optimizeLegibility !default;

//h1.display
$size-0: #{var(--font-size-h1-display)} !default;
$size-0-lh: #{var(--font-size-h1-display)} !default;
$size-0-mobile-display: #{var(--font-size-h1-display-mobile)} !default;
$size-0-mobile: #{var(--font-size-h1-mobile)} !default;

//h1
$size-1: #{var(--font-size-h1)} !default;
$size-1-lh: #{var(--font-size-h1-lh)}  !default;

//h2
$size-2:  #{var(--font-size-h2)} !default;
$size-2-lh:  #{var(--font-size-h2-lh)} !default;
$size-2-mobile:  #{var(--font-size-h2-mobile)} !default;

//h3
$size-3: #{var(--font-size-h3)} !default;
$size-3-lh: #{var(--font-size-h3-lh)} !default;
$size-3-mobile: #{var(--font-size-h3-mobile)} !default;

//h4
$size-4: #{var(--font-size-h4)} !default;
$size-4-lh: #{var(--font-size-h4-lh)} !default;
$size-4-mobile: #{var(--font-size-h4-mobile)} !default;

//h5
$size-5: #{var(--font-size-h5)} !default;
$size-5-lh:  #{var(--font-size-h5-lh)} !default;
;

//h6
$size-6: #{var(--font-size-h6)} !default;
$size-6-lh: #{var(--font-size-h6-lh)} !default;

//p 
$size-7: #{var(--font-size-p)} !default;
$size-7-lh: #{var(--font-size-p-lh)} !default;
$size-7-lh-content: #{var(--font-size-p-lh-content)} !default;

//small / base font-size
$size-8: #{var(--font-size-small)} !default;


//eyebrow
$size-eyebrow: 1rem !default;
$size-eyebrow-ls: 0.09375rem !default;
$size-eyebrow-lh: 1.375rem !default;

// Menu
$size-menu: 1.0625rem !default;
$size-menu-icon: 1.5rem !default;

$lineheight-offset: 0.5rem !default;

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;
$weight-extrabold: 800 !default;
$weight-black: 900 !default;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default;

// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;

// 960px container + 4rem
$desktop: 960px + 2 * $gap !default;

// 1152px container + 4rem
$widescreen: 1152px + 2 * $gap !default;

// 1344px container + 4rem
$fullhd: 1344px + 2 * $gap !default;

// Miscellaneous 
$easing: ease-out !default;
$radius-small: var(--radius-sm) !default;
$radius: var(--radius) !default;
$radius-large: var(--radius-lg) !default;
$radius-rounded: 290486px !default;
$speed: 86ms !default;

// Flags
$variable-columns: true !default;
$css-variables: true !default;

// Images
$hero-image: $secondary !default;


//Masthead Height
$navbar-height: 5.25rem !default;
$masthead-height: 29px !default; //px used is intended to fix the font-size and height for consistency
$masthead-gutter: 4px !default; //px used is intended to fix the font-size and height for consistency

// Alignment / Spaces
$base-margin-padding: var(--global-alignment) !default;
$spacing-maps: (
  "xs": var(--global-alignment-multiplier-xs),
  "sm": var(--global-alignment-multiplier-sm),
  "lg": var(--global-alignment-multiplier-lg),
  "xl": var(--global-alignment-multiplier-xl),
  "none": 0,
) !default;
$position-maps: top, right, bottom, left !default;


