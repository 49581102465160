@charset 'UTF-8';

$family-sans-serif: 'Source Sans Pro', BlinkMacSystemFont, -apple-system, "Segoe UI", sans-serif;
$body-color: #323232;

@import "./sass/sgds-customise/all";
@import "./sass/sgds-base/all";
@import "grid";
@import "navbar";
@import "sgds-example";
@import "content-typography";
@import "sgds-syntax";
@import "mobile-side-nav";
@import "spinner";
@import "docs";
@import "request-component";
@import "sidenav";

/* Keep footer at bottom of viewport if page isn't high enough */
html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  &.has-navbar-fixed-top {
    padding-top: calc(5.25rem + 41px);
  }
  @media screen and (max-width: 1023px) {
    &.has-navbar-fixed-top {
      padding-top: calc(5.25rem + 49px);
    }
  }
}

.content-page {
  flex: 1 0 auto;
}
.sgds-footer {
  flex-shrink: 0;
}

/* External links */
a[target="_blank"]:after {
  display: inline-block;
  font-family: sgds-icons;
  content: "\e95c";
  padding-left: 0.25rem;
  font-weight: 400;
}
/* Docs */
.component-card {
  height: 100%;
}

.language-selector {
  background: transparent;
  border: 0;
  font-size: 14px;
}

.card-section .col a {
  width: 100%;
}

/* Sidenav performance: https://abouolia.github.io/sticky-sidebar/ */
.sidenav {
  will-change: min-height;
}

.sidebar__inner {
  transform: translate(0, 0); /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}

.has-background-gradient {
  background: rgb(96, 55, 179);
  background: linear-gradient(
    30deg,
    rgba(96, 55, 179, 1) 0%,
    rgba(70, 117, 211, 1) 100%
  );
}

.content-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header_bg {
  position: absolute;
  top: -24px;
  right: 0;
  width: 55vw;
  height: 420px;
  background-color: #7b50ce;
  transform: skewX(-32deg);
  transform-origin: right bottom;
}

.hide-element {
  overflow: hidden;
  position: relative;
}

.sgds-card-button.has-outline {
  background-color: #ffffff;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #484848;
  transition: 0.2s all;
  &:hover {
    background-color: #f6f6f6;
  }
}

.hero-main {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sgds-breadcrumb[class*="has-background"] {
  li {
    &::before {
      color: white;
    }
  }
}