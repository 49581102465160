@mixin example-tag($content) {
  content: $content;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.05rem 0.5rem;
  font-size: 0.7rem;
  background: $warning;
  color: black !important;
}

@mixin example-control($padding, $text-align) {
  border: 1px dotted $info;
  padding: $padding;
  background-color: rgba($info, 0.2);
  position: relative;
  text-align: $text-align;
}

.sgds-example {
  width: 100%;
  margin: 0.75rem;
  @include mobile() {
    margin: 0;
  }
  padding: 0.3rem;
  border: 1px solid $warning;

  .sgds-menu{
    ul{
      list-style: none;
    }
  }
  .sgds-section {
    @include example-control(auto, center);
    &:before {
      @include example-tag(".sgds-section");
    }
    &.is-small {
      &:before {
        content: ".sgds-section.is-small" !important;
      }
    }
    &.is-medium {
      &:before {
        content: ".sgds-section.is-medium" !important;
      }
    }
    &.is-large {
      &:before {
        content: ".sgds-section.is-large" !important;
      }
    }
  }
  .sgds-container {
    width: inherit;
    @include example-control(2.5rem, center);
    &:before {
      @include example-tag(".sgds-container");
    }
    &.is-fluid {
      &:before {
        content: ".sgds-container.is-fluid";
      }
    }
  }
  .row {
    @include example-control(1.5rem, center);
    &:before {
      @include example-tag(".row");
    }
    &.is-multiline:before {
      @include example-tag(".row .is-multiline");
    }
    &.has-text-centered {
      text-align: center;
    }
    &.is-mobile {
      &:before {
        @include example-tag(".row.is-mobile");
      }
    }
    &.is-desktop {
      &:before {
        @include example-tag(".row.is-desktop");
      }
    }
  }
  .col {
    @include example-control(0rem, left);
    //background-color: rgba(white,0.8);
    color: black;
    text-align: center;
    &:before {
      @include example-tag(".col");
    }
    @for $i from 2 through 12 {
      @for $j from 2 through 12 {
        &.is-#{$i} {
          &:before {
            @include example-tag(".col" + ".is-" + "#{$i}");
          }
        }
        &.is-#{$j}.is-offset-#{$i} {
          &:before {
            @include example-tag(
              ".col" + ".is-"+ "#{$j}"+".is-offset-"+"#{$i}"
            );
          }
        }
      }
    }
    &.is-nested {
      padding: 2rem;
    }
  }
  //example util
  &.no-styling {
    div,
    section {
      background-color: white !important;
      border: 0 !important;

      &:before {
        display: none;
      }
    }
    section {
      padding: 3rem 1.5rem !important;
    }
  }
  &-others {
    width: 100%;
    margin-top: 0.75rem;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-bottom: 0;
    background-color: #fff;

    .sgds-masthead{
      .col{
        padding: 0;
      }
      .row{
        margin-left: 0;
        padding: 0 0.5rem;
      }
      #sgds-masthead-content{
        padding-top: 1.375rem;
        padding-bottom: 2.75rem;
      }
    }
    .sgds-button{
      margin-bottom: 0;
    }
    .sgds-container {
      width: auto;
    }
    .sgds-menu{
      ul{
        margin:0;
      }
      li{
        list-style: none;
      }
    }
    .sgds-modal{
      button{
        margin-bottom: 0;
      }
      p.sgds-modal-card-title{
        margin-bottom: 0;
      }
    }
    .sgds-breadcrumb{
      ul,li{
        margin:0;
      }
    }
    .field {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
    :where(h1,h2) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.responsive-table-cell {
  padding: 0.5rem;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $info;
    padding: 1rem;
    height: 60px;
    color: white;
    &.unchanged {
      background-color: $grey-lighter;
      color: $grey-dark;
    }
  }
}
