$info: $cyan !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;

$light: $white-ter !default;
$dark: $grey-darker !default;

// Invert colors

$orange-invert: findColorInvert($orange) !default;
$yellow-invert: findColorInvert($yellow) !default;
$green-invert: findColorInvert($green) !default;
$turquoise-invert: findColorInvert($primary) !default;
$cyan-invert: findColorInvert($cyan) !default;
$blue-invert: findColorInvert($blue) !default;
$purple-invert: findColorInvert($purple) !default;
$red-invert: findColorInvert($red) !default;

$primary-invert: findColorInvert($primary) !default;
$primary-light: findLightColor($primary) !default;
$primary-dark: findDarkColor($primary) !default;
$secondary-invert: findColorInvert($secondary) !default;
$secondary-light: findLightColor($secondary) !default;
$secondary-dark: findDarkColor($secondary) !default;
$info-invert: findColorInvert($info) !default;
$info-light: findLightColor($info) !default;
$info-dark: findDarkColor($info) !default;
$success-invert: findColorInvert($success) !default;
$success-light: findLightColor($success) !default;
$success-dark: findDarkColor($success) !default;
$warning-invert: findColorInvert($warning) !default;
$warning-light: findLightColor($warning) !default;
$warning-dark: findDarkColor($warning) !default;
$danger-invert: findColorInvert($danger) !default;
$danger-light: findLightColor($danger) !default;
$danger-dark: findDarkColor($danger) !default;
$light-invert: findColorInvert($light) !default;
$dark-invert: findColorInvert($dark) !default;

// General colors
$scheme-main: $white !default;
$scheme-main-bis: $white-bis !default;
$scheme-main-ter: $white-ter !default;
$scheme-invert: $black !default;
$scheme-invert-bis: $black-bis !default;
$scheme-invert-ter: $black-ter !default;


$background: $white-ter !default;

$border: $grey-light !default;
$border-hover: $secondary !default;

// Text colors

$text: $grey-dark !default;
$text-invert: findColorInvert($text) !default;
$text-light: $grey !default;
$text-strong: $grey-darker !default;

// Code colors

$code: $red !default;
$code-background: $background !default;

$pre: $text !default;
$pre-background: $background !default;

// Link colors

$link: $blue !default;
$link-invert: findColorInvert($link) !default;
$link-light: findLightColor($link) !default;
$link-dark: findDarkColor($link) !default;
$link-visited: $purple !default;

$link-hover: lighten($blue, 5%) !default;
$link-hover-border: $grey-light !default;

$link-focus: lighten($blue, 5%) !default;
$link-focus-border: $blue !default;

$link-active: $grey-darker !default;
$link-active-border: $grey-dark !default;

// Typography
$family-primary: $family-sans-serif !default;
$family-code: $family-monospace !default;

$size-small: $size-8 !default;
$size-normal: $size-7 !default;
$size-medium: $size-6 !default;
$size-large: $size-5 !default;

// Lists and maps

$custom-colors: null !default;
$custom-shades: null !default;

$colors: mergeColorMaps(
  (
    "primary": (
      $primary,
      $primary-invert,
    ),
    "secondary": (
      $secondary,
      $secondary-invert,
    ),
    "white": (
      $white,
      $black,
    ),
    "black": (
      $black,
      $white,
    ),
    "light": (
      $light,
      $light-invert,
    ),
    "dark": (
      $dark,
      $dark-invert,
    ),
    "link": (
      $link,
      $link-invert,
    ),
    "info": (
      $info,
      $info-invert,
    ),
    "success": (
      $success,
      $success-invert,
    ),
    "warning": (
      $warning,
      $warning-invert,
    ),
    "danger": (
      $danger,
      $danger-invert,
    ),
  ),
  $custom-colors
) !default;
$shades: mergeColorMaps(
  (
    "black-bis": $black-bis,
    "black-ter": $black-ter,
    "grey-darker": $grey-darker,
    "grey-dark": $grey-dark,
    "grey": $grey,
    "grey-light": $grey-light,
    "grey-lighter": $grey-lighter,
    "white-ter": $white-ter,
    "white-bis": $white-bis,
  ),
  $custom-shades
) !default;
$sizes: $size-0 $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8 !default;
$fontweights: mergeColorMaps(
  (
    "weight-light": $weight-light,
    "weight-normal": $weight-normal,
    "weight-medium": $weight-medium,
    "weight-semibold": $weight-semibold,
    "weight-bold": $weight-bold,
    "weight-extrabold": $weight-extrabold,
    "weight-black": $weight-black,
  ),
  $custom-shades
) !default;

