h1 {
  font-size: $size-1;
  line-height: $size-1-lh;
  letter-spacing: -0.09375rem;

  @include touch {
    font-size: $size-0-mobile;
  }


  &.display {
    font-size: $size-0;
    line-height: $size-0-lh;

    @include touch {
      font-size: $size-0-mobile-display;
    }
  }
}

h2 {
  font-size: $size-2;
  line-height: $size-2-lh;

  @include touch {
    font-size: $size-2-mobile;
  }
}

h3 {
  font-size: $size-3;
  line-height: $size-3-lh;

  @include touch {
    font-size: $size-3-mobile;
  }
}

h4 {
  font-size: $size-4;
  line-height: $size-4-lh;

  @include touch {
    font-size: $size-4-mobile;
  }
}

h5 {
  font-size: $size-5;
  line-height: $size-5-lh;
}

h6 {
  font-size: $size-6;
  line-height: $size-6-lh;
}

p, li, center {
  font-size: $size-7;
  line-height: $size-7-lh;

  .content & {
    line-height: $size-7-lh-content;
  }
}

small {
  font-size: $size-8;
  line-height: $size-7;
}

b, strong {
  font-weight: $weight-semibold;
}

.eyebrow {
  font-size: $size-8;
  letter-spacing: $size-eyebrow-ls;
  line-height: $size-eyebrow-lh;
}
