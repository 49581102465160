.grid-container{
    display:grid;
    

    &.template-sidenav{
        grid-template-columns: 15rem 1fr;
        grid-gap: 6rem;
        grid-template-areas: 
            "aside article";

        @include until($desktop){
            grid-template-columns: 1fr;
            grid-template-areas: "article";
        }

        aside{
            grid-area: aside;
            .sgds-menu{
                position: sticky;
                top:1rem;
            }
            @extend .is-hidden-touch;
        }

        article{
            grid-area: article;
        }
    }

    &.col-3-grid{
        grid-template-columns: repeat(auto-fill, minmax(15.625rem, 1fr));
        grid-gap: 0.5rem;
        .icon-clipboard-btn{
            padding:1rem;
            border: 1px solid $grey-lighter;
        }
    }
}