h1,
h2,
h3,
h4,
h5,
h6,
th {
  font-weight: bold;
  [class*="sgds-example"] &{
    margin-top:0;
    margin-bottom:0;
  }
}

h2 {
  color: $primary;
  margin-bottom: 2rem;
}
h3 {
  margin-bottom: 1rem;
}
h4 {
  margin-bottom: 0;
  margin-top: 1em;
}

p {
  margin-bottom: 1rem;
  &:nth-last-child {
    margin-bottom: 0;
  }
  [class*="sgds-example"] &{
    margin-top:0;
    margin-bottom:0;
  }
}


hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.highlight {
  margin-top: 0 !important;
  margin-bottom: 2rem;
  &:nth-last-child {
    margin-bottom: 0;
  }
}

i {
  color: lighten(#323232, 20%);
}

.mobile-side-nav .sgds-dropdown-trigger button {
  margin-bottom: 0;
}

button {
  margin-bottom: 1rem;
  [class*="sgds-navbar"] &{
    margin-bottom:0;
  }
}

// text length readability
@supports (width: 10ch){
  .custom-content{
    & > p,
    & > ul li{
      @include from($desktop) {
          width: min(75ch);
      }
    }
  }
}