.mobile-side-nav {
  position: sticky;
  width: 100%;
  // top: calc(#{$navbar-height} + #{$masthead-height} + 21px);
  z-index: 40;
  top: 0;

  .sgds-dropdown-content {
    height: 40vh;
    overflow-y: scroll;
  }
}

body.has-navbar-fixed-top {
  &.has-component-dropdown {
    @include touch() {
      padding-top: calc(#{$navbar-height} + #{$masthead-height} + 71px);
    }
  }
}
